import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TAPPIDS from '../../../../constants/tapp-ids';
import { SmartClientSystem } from '../../../../constants/defaults';
import ApiV5Tapp from '../ApiV5Tapp/ApiV5Tapp';
import { loadSmartClientStore, setActiveTapp, setSelectedItem } from '../../../../redux-modules/app/actions';
import { getAppState } from '../../../../redux-modules/app/selector';
import appCall from '../../../../utils/appCall';
import LoadingComponent from '../../../util-components/LoadingComponent';
import logger from "../../../../constants/logger";

const SmartClientTapp = ({ loadSelectedMail, davidSiteId, mailId, mail, isNewMail = false }) => {
    const dispatch = useDispatch();
    const { activeTapp } = useSelector(getAppState);
    const isAppInfoCenter = activeTapp === TAPPIDS.APP_INFO_CENTER;
    const isSiteTapp = activeTapp === TAPPIDS.INFO_CENTER;
    const [isLoading, setIsLoading] = useState(false);

    const infoCenterGoBack = () => {
        appCall(292, {
            show: true,
        }, {
            awaitResult: false,
        });
        dispatch(setSelectedItem(null));
    };

    useEffect(() => {
        dispatch(loadSmartClientStore(true));
    }, []);

    useEffect(() => {
        if (isLoading) {
            const loadStart = Date.now();
            const timeout = setTimeout(() => {
                logger().warning({
                    message: 'SmartClient loading longer than 5 seconds',
                });
            }, 5_000);

            return () => {
                clearTimeout(timeout);
                const loadEnd = Date.now();
                logger().info({
                    message: 'SmartClient load finished',
                    customNumber: loadEnd - loadStart,
                });
            };
        }

        return undefined;
    }, [isLoading]);

    return (
        <ApiV5Tapp customData={{ loadStore: false, loadSelectedMail, davidSiteId, mailId, mail, loadBoth: false, infocenter: isAppInfoCenter || isSiteTapp, infoCenterGoBack, isNewMail }} type="client-module" tappId={TAPPIDS.SMART_CLIENT} system={SmartClientSystem} loadingComponent={<LoadingComponent setIsLoading={setIsLoading}/>}/>
    );
};

export default SmartClientTapp;
