import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import appCall from '../appCall';
import { addToolbarChangeListener } from "../toolbarChangeListener";

const useAnimatedToolbar = ({ enabled, onChange, scrollContainer, addMinHeight }) => {
    const initialToolbarHeight = useRef();
    const initialHotCardHeight = useRef();
    const hiddenRef = useRef(true);

    const [appSupported, setAppSupported] = useState(false);

    const setHeight = async (toolbarHeight, hotCardHeight) => {
        onChange({
            toolbarHeight, hotCardHeight,
        });
        initialToolbarHeight.current = toolbarHeight;
        initialHotCardHeight.current = hotCardHeight;
        const value = toolbarHeight + hotCardHeight;
        if (value === 0) {
            document.body.style.removeProperty('--app-bottom-height');
        } else {
            document.body.style.setProperty('--app-bottom-height', `${toolbarHeight + hotCardHeight}px`);
        }
    };

    useEffect(() => {
        if (!enabled) {
            return undefined;
        }

        const setInitialHeight = async () => {
            await new Promise((x) => setTimeout(x, 100));
            const { AppInfo } = await appCall(18);
            if (typeof AppInfo.initialToolbarHeight !== 'number') return undefined;
            AppInfo.initialHotCardHeight = AppInfo.initialHotCardHeight || 0;
            AppInfo.initialToolbarHeight = AppInfo.initialToolbarHeight || 0;
            if (!hiddenRef.current) {
                AppInfo.initialHotCardHeight = 0;
                AppInfo.initialToolbarHeight = 0;
            }
            if(addMinHeight) document.documentElement.style.minHeight = `calc(100vh + ${AppInfo.initialToolbarHeight + AppInfo.initialHotCardHeight}px)`;
            setHeight(AppInfo.initialToolbarHeight, AppInfo.initialHotCardHeight);
            setAppSupported(true);

            window.dispatchEvent(new Event('resize'));

            // setTimeout(() => {
            //     window.dispatchEvent(new Event('resize'));
            // }, 500);

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 20000);
            return undefined;
        };
        setInitialHeight();

        if (!appSupported) return undefined;
        addToolbarChangeListener((data) => {
            if (!data.isVisible) {
                data.hotCardHeight = 0;
                data.toolbarHeight = 0;
            }

            data.hotCardHeight = data.hotCardHeight || 0;

            hiddenRef.current = data.isVisible;
            setHeight(data.toolbarHeight, data.hotCardHeight);
        });

        let lastScrollPos = null;

        const handleScroll = throttle(() => {
            const scrollTop = scrollContainer.scrollTop ?? scrollContainer.scrollY;

            let hidden = null;

            if (scrollTop < 0 || (scrollContainer.scrollHeight < (scrollContainer.scrollTop + scrollContainer.offsetHeight))) return;
            if (scrollTop >= 0 && (scrollTop - lastScrollPos) > 25) {
                if (hiddenRef.current === null || hiddenRef.current === true) {
                    hidden = false;
                }
            } else if ((Math.abs(scrollTop - lastScrollPos) > 25 || scrollTop <= 0) && (hiddenRef.current === null || hiddenRef.current === false)) {
                hidden = true;
            }

            if (hidden !== null) {
                hiddenRef.current = hidden;
                appCall(292, {
                    show: hidden,
                }, {
                    awaitResult: false,
                });
                onChange({
                    toolbarHeight: hidden ? initialToolbarHeight.current : 0,
                    hotCardHeight: hidden ? initialHotCardHeight.current : 0,
                });
            }

            lastScrollPos = scrollTop;
        }, 100);
        const handleScrollEnd = () => {
            handleScroll.flush();
        };

        scrollContainer.addEventListener('scroll', handleScroll, { passive: true });
        scrollContainer.addEventListener('scrollend', handleScrollEnd, { passive: true });
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
                scrollContainer.removeEventListener('scrollend', handleScrollEnd);
            }
        };
    }, [enabled, onChange, appSupported]);
};

export default useAnimatedToolbar;
