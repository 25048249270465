import React, { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import {
    ChaynsHost,
    Environment,
    Font,
    Gender,
    getDeviceInfo,
    OpenImage,
    RuntimeEnviroment,
    useFunctions,
} from 'chayns-api';
import { useDispatch, useSelector } from 'react-redux';
import TAPPIDS from '../../../../constants/tapp-ids';
import { setIsMenuShown, setIsScannerShown } from '../../../../redux-modules/app/actions';
import { getKeyForTapp, removeKeyForTapp, setKeyForTapp } from '../../../../utils/chayns-storage';
import { isDEVELOPMENT, isQA, isSTAGING } from '../../../../constants/environments';
import { setImageWrapper, setWaitCursor } from '../../../../redux-modules/components/actions';
import { getUserState } from '../../../../redux-modules/user/selector';
import {getAppState, getSafeAreas} from '../../../../redux-modules/app/selector';
import { getTappsState } from '../../../../redux-modules/tapps/selector';
import selectTab from '../../../../calls/json-chayns-call/calls/select-tapp';
import { getEnvState } from '../../../../redux-modules/env/selector';
import DialogHelper from '../../../../calls/dialog/DialogHelper';
import appCall, { addGeoLocationListener, getGeoLocation, removeGeoLocationListener } from '../../../../utils/appCall';
import { addToolbarChangeListener, removeToolbarChangeListener } from '../../../../utils/toolbarChangeListener';
import { PRIMARY_COLOR } from '../../../../constants/colors';
import { SITE_ID } from '../../../../constants/site';
import { addWindowMetricsListener, removeWindowMetricsListener } from '../../../../utils/windowMetricsListener';
import postTobitAccessTokenForm from '../../../../utils/postTobitAccessToken';
import getLanguage from "../../../../utils/getLanguage";

const PULL_TO_REFRESH_CALL_NUMBER = 0;
const OPEN_DIALOG_CALL_NUMBER = 184;
const CLOSE_DIALOG_CALL_NUMBER = 113;
const SHOW_TOAST_NOTIFICATION_CALL_NUMBER = 276;
let callbackId = 0;

export const invokeDialogCall = (call, callback) => {
    const callbackName = `cwCallback${callbackId}`;
    window[callbackName] = (e) => {
        callback(e?.retVal);
    };
    if ([OPEN_DIALOG_CALL_NUMBER, PULL_TO_REFRESH_CALL_NUMBER, CLOSE_DIALOG_CALL_NUMBER, SHOW_TOAST_NOTIFICATION_CALL_NUMBER].includes(call.action)) {
        window.dialog.receiveApiCall({
            ...call,
            value: { ...(call?.value || {}), callback: `window.${callbackName}` },
        }, 'chayns.ajaxTab.jsoncall');
    }

    if (call.action === 218) {
        window.receiveIframeDialogMessage(call, true);
    }

    callbackId++;
};

const ApiV5Tapp = ({
    tappId,
    tappIframeWrapperRef,
    iFrameStyles,
    overlayActive,
    isMobileBrowser,
    isMyChaynsApp,
    type = 'client-iframe',
    tappUrl,
    system,
    wrapperClassNames,
    customData,
    loadingComponent,
    postTobitAccessToken,
}) => {
    const { isClient } = useSelector(getAppState);
    const safeAreas = useSelector(getSafeAreas);

    const functions = useFunctions();

    const dispatch = useDispatch();
    const user = useSelector(getUserState);
    const env = useSelector(getEnvState);
    const appState = useSelector(getAppState);
    const tapps = useSelector(getTappsState);
    const language = useMemo(() => (global.window ? {
        site: window.chaynsDeLanguage,
        translation: null,
        device: window.chaynsDeLanguage,
        active: window.chaynsDeLanguage,
    } : null), []);
    // const activeTapp = useSelector(getActiveTapp);
    const tapp = tapps.find((x) => x.tappId === tappId);

    const name = `TappIFrame_${tappId}`;

    const dialog = useMemo(() => new DialogHelper(), []);

    const paramObject = useMemo(() => {
        const paramString = tapp?.params?.join('&');
        if (!paramString) {
            return {};
        }
        try {
            return Object.fromEntries(new URLSearchParams(paramString));
        } catch (e) {
            console.error(e);
            return {};
        }
    }, [tapp?.params]);

    const values = useMemo(() => (isClient ? {
        site: {
            id: SITE_ID,
            url: window?.location.href.split('#')[0],
            urlHash: window?.location.hash.replace('#', ''),
            locationPersonId: 'unknown',
            title: 'chayns®',
            dynamicFontSize: false,
            font: {
                id: Font.Roboto,
                dynamicFontSize: false,
                headlineFont: Font.Roboto,
            },
            domain: 'tobit.team',
            colorMode: appState.colorMode === 'dark' ? 1 : 0,
            color: appState.activeTapp === TAPPIDS.APP_INFO_CENTER ? PRIMARY_COLOR : appState.color,
            layoutDisposition: {
                contentWide: false,
                barOnTop: false,
                barWide: false,
                coverDetached: false,
                coverHidden: false,
                coverWide: false,
                docked: false,
            },
            locationId: 378,
        },
        isAdminModeActive: false,
        currentPage: {
            id: tappId,
            siteId: SITE_ID,
        },
        device: getDeviceInfo(navigator.userAgent, 'image/webp'),
        user: {
            userId: user?.userId,
            uacGroups: [],
            personId: user?.personId,
            firstName: user?.firstname,
            lastName: user?.lastname,
            gender: Gender.Unknown,
        },
        language,
        parameters: { ...paramObject, ...appState.params, ...(tapp?.queryParameters || {}) },
        environment: {
            buildEnvironment: isDEVELOPMENT ? Environment.Development : isQA ? Environment.Qa : isSTAGING ? Environment.Staging : Environment.Production,
            runtimeEnvironment: RuntimeEnviroment.ChaynsDe,
        },
        customData: {
            ...(customData || {}),
            safeAreas,
        },
        accessToken: typeof chayns !== 'undefined' ? (chayns.env.user.tobitAccessToken || user?.token) : user?.token,
    } : {}), [appState.activeTapp, appState.color, appState.colorMode, appState.params, customData, language, paramObject, safeAreas, tapp?.queryParameters, tappId, user?.firstname, user?.lastname, user?.personId, user?.token, user?.userId]);

    useEffect(() => {
        if (postTobitAccessToken && isClient) {
            postTobitAccessTokenForm(tappUrl, JSON.stringify(values), 'chayns', tappId > 0 ? tappId : tappId * -1);
        }
    }, [postTobitAccessToken, isClient]);

    if (!isClient) return false;
    let parsedUrl;
    let urlParams;
    if (tappUrl && !postTobitAccessToken) {
        parsedUrl = new URL(tappUrl);
        urlParams = new URLSearchParams(parsedUrl.search);
        urlParams.set('siteId', SITE_ID);
        urlParams.set('colorMode', appState.colorMode === 'light' ? '0' : '1');
        const lang = getLanguage();
        urlParams.set('translang', lang);
        urlParams.set('lang', lang);
    }

    if (global.window) {
        if (appState.activeTapp === TAPPIDS.APP_INFO_CENTER) {
            chayns.env.site.color = PRIMARY_COLOR; // change intercom color
        }
    }

    const deviceInfo = getDeviceInfo(navigator.userAgent, 'image/webp');

    return (
        <>
            <div
                className={wrapperClassNames}
                style={iFrameStyles}
                ref={tappIframeWrapperRef}
                onClick={() => {
                    if (overlayActive) {
                        dispatch(setIsScannerShown(false));
                        dispatch(setIsMenuShown(false));
                    }
                }}
            >
                <ChaynsHost
                    {...values}
                    src={parsedUrl ? `https://${parsedUrl.host + parsedUrl.pathname}?${urlParams.toString()}` : undefined}
                    type={type}
                    system={system}
                    iFrameProps={{
                        className: classNames('tapp-iframe apiv5', {
                            desktop: !isMobileBrowser && !isMyChaynsApp,
                            'login-tapp': tappId === TAPPIDS.LANDING_PAGE,
                            mobile_browser: isMobileBrowser,
                        }),
                        name,
                        id: name,
                        allowFullScreen: true,
                        allowpaymentrequest: '',
                        allow: 'geolocation; microphone; camera; autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media; clipboard-read; clipboard-write',
                        title: 'Page',
                    }}
                    pages={tapps.map((x) => ({
                        id: x.id,
                        name: '',
                        uacGroups: [],
                        customUrl: x.customUrl,
                        icon: x.icon,
                        siteId: SITE_ID,
                        iconType: 0,
                        isExclusive: true,
                        isHiddenFromMenu: false,
                        minAge: null,
                        sortId: 0,
                    }))}
                    loadingComponent={loadingComponent}
                    functions={{
                        getAccessToken: () => ({ accessToken: typeof chayns !== 'undefined' ? (chayns.env.user.tobitAccessToken || user?.token) : user?.token }),
                        setFloatingButton: (data, callback) => {
                            data.callback = callback;
                            data.enabled = data.isEnabled;
                            data.id = data.id || tappId;
                            window.handleChaynsCalls('floatingButton', data);
                        },
                        addGeoLocationListener: (value, callback) => addGeoLocationListener(value, callback, { isMyChaynsApp }),
                        getGeoLocation: (value) => getGeoLocation(value, { isMyChaynsApp }),
                        removeGeoLocationListener,
                        storageSetItem: (key, value, accessMode, tappIds) => setKeyForTapp(key, value, accessMode, tappIds),
                        storageGetItem: (key, accessMode) => getKeyForTapp(key, accessMode),
                        removeToolbarChangeListener: (callbackId) => removeToolbarChangeListener(callbackId),
                        storageRemoveItem: (key, accessMode) => removeKeyForTapp(key, accessMode),
                        invokeDialogCall: (value, callback) => invokeDialogCall(value, callback),
                        setWaitCursor: (v) => {
                            dispatch(setWaitCursor({
                                ...(v || {}),
                                isEnabled: v?.enabled,
                            }));
                        },
                        invokeCall: async (value, callback) => appCall(value.action, value.value, { callback, awaitResult: true }),
                        addToolbarChangeListener: (callback) => addToolbarChangeListener(callback),
                        getWindowMetrics: () => ({
                            bottomBarHeight: 0,
                            topBarHeight: 0,
                            offsetTop: 0,
                            windowHeight: window.innerHeight,
                            pageHeight: 0,
                            pageWidth: window.innerWidth,
                            pageSize: null,
                        }),
                        selectPage: async (value) => {
                            if (value.siteId && value.siteId !== '60021-08989') {
                                const path = value.path || `tapp/${value.id}`;
                                const url = `https://chayns.site/${value.siteId}/${path}`;
                                if (env.isMyChaynsApp) {
                                    appCall(9, { url, checkForChaynsSite: true });
                                } else {
                                    window.open(`https://chayns.site/${value.siteId}/${path}`, '_blank');
                                }
                                return;
                            }
                            if ([552789, -2, 250359, 250357, 251441].includes(value.id) && env.isMyChaynsApp) {
                                let params = new URLSearchParams();
                                if (value.params) {
                                    params = new URLSearchParams(value.params);

                                    // workaround
                                    setTimeout(() => {
                                        appCall(2, { ...value, params: params.toString() });
                                    }, 1000);
                                }
                                appCall(2, { ...value, params: params.toString() });
                                return;
                            }
                            selectTab(value);
                        },
                        addWindowMetricsListener,
                        removeWindowMetricsListener,
                        ...(deviceInfo.app.name ? {
                            openDialog: functions.openDialog,
                            closeDialog: functions.closeDialog,
                            dispatchEventToDialogClient: functions.dispatchEventToDialogClient,
                            addDialogClientEventListener: functions.addDialogClientEventListener,
                            createDialog: functions.createDialog,
                        } : {
                            openDialog: dialog.openDialog,
                            closeDialog: dialog.closeDialog,
                            dispatchEventToDialogClient: dialog.dispatchEventToDialogClient,
                            addDialogClientEventListener: dialog.addDialogClientEventListener,
                            createDialog: dialog.createDialog,
                        }),
                        openImage: (req: OpenImage) => {
                            dispatch(setImageWrapper({
                                urls: req.items.map((item) => item.url),
                                startIndex: req.startIndex || 0,
                            }));
                            return Promise.resolve();
                        },
                        openUrl: (value) => {
                            window.open(value.url, value.sameWindow ? '_self' : '_blank');
                            return Promise.resolve();
                        },
                    }}
                    preventStagingReplacement={(tappId === TAPPIDS.INTERCOM && !isSTAGING) || !!postTobitAccessToken}
                />
            </div>
            <style jsx>
                {`
                  .tapp-iframe-wrapper {
                    &.not-active {
                      height: 0;
                      min-height: 0 !important;
                    }
                  }
                `}
            </style>
        </>
    );
};

const ApiV5TappWrapper = (props) => {
    const { isClient } = useSelector(getAppState);
    const { isMyChaynsApp } = useSelector(getEnvState);

    if (!isClient && isMyChaynsApp) {
        return null;
    }

    return (
        <ApiV5Tapp {...props}/>
    );
};

export default memo(ApiV5TappWrapper);
