import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { getAppState } from '../../../../redux-modules/app/selector';
import { setShowTransitioningOverlay } from '../../../../redux-modules/app/actions';
import TobitTeamByDavidLogo from '../../../util-components/TobitTeamByDavidLogo';

const TransitionOverlay = () => {
    const dispatch = useDispatch();
    const { isSmartClientAvailable } = useSelector(getAppState);

    const timeout = useRef(null);
    const {
        showTransitioningOverlay,
    } = useSelector(getAppState);

    useEffect(() => {
        if (showTransitioningOverlay && !timeout.current) {
            timeout.current = setTimeout(() => {
                dispatch(setShowTransitioningOverlay(false));
                timeout.current = null;
            }, 1500);
        }
    }, [showTransitioningOverlay, dispatch]);

    return isSmartClientAvailable && (
        <>
            <CSSTransition
                timeout={1000}
                in={showTransitioningOverlay}
                classNames="transition-overlay-animation"
                unmountOnExit
                mountOnEnter
            >
                <div className="transition-overlay">
                    <div className="transition-overlay__logo">
                        <TobitTeamByDavidLogo width={308}/>
                    </div>
                    <div
                        className="transition-overlay__tobit-logo"
                        style={{
                            backgroundImage: 'url("/tobit_software_logo.svg")',
                        }}
                    />
                </div>
            </CSSTransition>
            <style jsx global>
                {`
                    .transition-overlay-animation {
                        &-enter {
                            opacity: 1;
                        }
                        
                        &-enter-active, &-enter-done {
                            opacity: 1;
                            transition: opacity 1000ms;
                        }

                        &-exit {
                            opacity: 1;
                        }

                        &-exit-active, &-exit-done {
                            opacity: 0;
                            transition: opacity 1000ms;
                        }
                    }

                    .transition-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 50;
                        
                        &__background {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            will-change: transform;
                            top: 0;
                            left: 0;
                        }
                        
                        &__logo {
                            width: 308px;
                            z-index: 50;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        
                        &__tobit-logo {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            height: 50px;
                            width: 160px;
                            background-repeat: no-repeat;
                            z-index: 50;
                            opacity: 0.6;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default TransitionOverlay;
