export const LIB_NAMES = ['chayns-de', 'tobit-team-runtime'];

const FALLBACKS = {};

const CHAYNS_DE_FALLBACKS = {
    login: 'Anmelden',
};

const TOBIT_TEAM_RUNTIME_FALLBACKS = {
    login_info: 'Unified Information. Unleashed Performance. <strong>Team/David</strong><span class="login-info__text__registered-trademark"><strong>®</strong></span>',
    app_link: 'Tobit.Team für ##PLATFORM##',
    more_team_david: 'Mehr über Team/David',
    new_mail: 'Neue eMail',
    noServer_headline: 'Willkommen bei Tobit.Team',
    noServer_text: 'Wir konnten leider keinen Server finden, der mit Ihrem chayns Account verbunden ist. Bitte prüfen Sie die Verbindung in Ihrem lokalen Client.',
    noServer_instructions: 'Folgen Sie der <a href="https://david.tobit.software/david-und-chayns" target="_blank">Anleitung</a>, um Ihren Account zu verknüpfen.',
    noServer_buttons_retry: 'Erneut versuchen',
    noServer_buttons_logout: 'Abmelden',
    minimumVersion_text: 'Für die Nutzung von Tobit.Team ist ein Update Ihres David Servers erforderlich. Wenden Sie sich an Ihren IT-Administrator.',
};

export const TEXT_STRING_FALLBACKS = {
    ...FALLBACKS,
    ...Object.keys(CHAYNS_DE_FALLBACKS).reduce((acc, key) => ({ ...acc, [`txt_chayns_de_${key}`]: CHAYNS_DE_FALLBACKS[key] }), {}),
    ...Object.keys(TOBIT_TEAM_RUNTIME_FALLBACKS).reduce((acc, key) => ({ ...acc, [`txt_tobit_team_runtime_${key}`]: TOBIT_TEAM_RUNTIME_FALLBACKS[key] }), {}),
};
