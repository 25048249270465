import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveServerState, getSelectedMailState } from '../../../../../../redux-modules/smart-client/selector';
import SmartClientTapp from '../../../smartclient-tapp/SmartClientTapp';

const SelectedMail: React.FC = () => {
    const mail = useSelector(getSelectedMailState);
    const server = useSelector(getActiveServerState);

    return (
        <SmartClientTapp loadSelectedMail mailId={mail?.id} mail={mail} davidSiteId={server.davidSiteId} isNewMail={!mail?.id} />
    );
};

SelectedMail.displayName = 'SelectedMail';

export default SelectedMail;
