import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getSafeAreas } from '../../redux-modules/app/selector';
import { setSafeAreas } from '../../redux-modules/app/actions';
import { getEnvState } from '../../redux-modules/env/selector';
import appCall from '../appCall';

const useSafeAreas = () => {
    const dispatch = useDispatch();
    const current = useSelector(getSafeAreas);
    const { isMyChaynsApp } = useSelector(getEnvState);

    useEffect(() => {
        if (isMyChaynsApp) {
            const callback = () => {
                appCall(18).then(({ AppInfo }) => {
                    if (typeof AppInfo.initialSafeAreaBottom === 'number') {
                        document.body.style.setProperty('--app-safe-area-bottom', `${AppInfo.initialSafeAreaBottom}px`);
                        dispatch(setSafeAreas({
                            bottom: AppInfo.initialSafeAreaBottom,
                        }));
                    }
                });
            };
            callback();
            window.addEventListener('orientationchange', callback);

            return () => {
                window.removeEventListener('orientationchange', callback);
            };
        }
        return undefined;
    }, [dispatch, isMyChaynsApp]);

    return current;
};

export default useSafeAreas;
