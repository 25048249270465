import React, { FC, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import Button from '@chayns-components/Button';
import { useSelector } from 'react-redux';
import TobitTeamByDavidLogo from '../util-components/TobitTeamByDavidLogo';
import { logout } from '../../utils/login';
import useGetTextString from '../../utils/hooks/useGetTextString';
import { getAppState } from '../../redux-modules/app/selector';
import { getEnvState } from '../../redux-modules/env/selector';
import appCall from '../../utils/appCall';
import { addToolbarChangeListener, removeToolbarChangeListener } from '../../utils/toolbarChangeListener';

const NoSmartclient: FC = () => {
    const { getTobitTeamTextString } = useGetTextString();
    const { hasServerMinimumVersion } = useSelector(getAppState);
    const { isMyChaynsApp } = useSelector(getEnvState);

    const handleLogout = useCallback(() => {
        if (isMyChaynsApp) {
            appCall(56);
        } else {
            logout();
        }
    }, [isMyChaynsApp]);

    useEffect(() => {
        if (isMyChaynsApp) {
            appCall(292, {
                show: false,
            }, {
                awaitResult: false,
            });
            const id = addToolbarChangeListener(({ isVisible }) => {
                if (isVisible) {
                    appCall(292, {
                        show: false,
                    }, {
                        awaitResult: false,
                    });
                }
            });

            return () => {
                removeToolbarChangeListener(id);
            };
        }
        return undefined;
    }, [isMyChaynsApp]);

    return (
        <>
            <div className="no-smartclient">
                <div className="no-smartclient__content">
                    <div className="no-smartclient__logo">
                        <TobitTeamByDavidLogo width={308}/>
                    </div>
                    <div className="no-smartclient__content__text">
                        {hasServerMinimumVersion && <h1 className="no-smartclient__headline">{getTobitTeamTextString('noServer_headline')}</h1>}
                        <p
                            className="no-smartclient__text"
                            dangerouslySetInnerHTML={{ __html: getTobitTeamTextString(hasServerMinimumVersion ? 'noServer_text' : 'minimumVersion_text') }}
                        />
                        {hasServerMinimumVersion && (
                            <span
                                className="no-smartclient__instructions"
                                dangerouslySetInnerHTML={{ __html: getTobitTeamTextString('noServer_instructions') }}
                            />
                        )}
                    </div>
                    <div className="no-smartclient__buttons">
                        {hasServerMinimumVersion && (
                            <Button onClick={() => location.reload()} className="no-smartclient__button">
                                {getTobitTeamTextString('noServer_buttons_retry')}
                            </Button>
                        )}
                        <Button onClick={handleLogout} className="no-smartclient__button">
                            {getTobitTeamTextString('noServer_buttons_logout')}
                        </Button>
                    </div>
                </div>
            </div>
            <img
                className={clsx('login-background')}
                src="/team-background.png"
                alt="Tobit.Team"
            />
            <style jsx global>
                {`
                    .no-smartclient {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        color: white;

                        &__content {
                            max-width: 500px;
                            z-index: 50;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 0 20px;

                            &__text {
                                margin-bottom: 30px;
                                
                                a {
                                    border-bottom-color: white;
                                }
                            }
                        }

                        &__headline {
                            margin-bottom: 10px;
                            color: white;
                        }

                        &__text {
                            margin-bottom: 20px;
                        }

                        &__buttons {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            >:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                        
                        &__instructions {
                            > a {
                                border-bottom-color: white;
                            }
                        }

                        &__button {
                            z-index: 50;
                            margin-top: 20px;
                            color: rgb(15, 109, 126) !important;
                            background-color: rgb(243, 243, 243) !important;
                            font-size: 19px;
                        }

                        &__logo {
                            width: 308px;
                            z-index: 50;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 20px;
                        }
                    }


                    .login-background {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        will-change: transform;
                        z-index: 32;
                        top: 0;
                        left: 0;
                    }
                `}
            </style>
        </>
    );
};

export default NoSmartclient;
